import http from '@/utils/httpRequest'

// 列表获取
export function getList(params) {
    return http({
        url: http.adornUrl('/hbi/hbireport/list'),
        method: 'get',
        params: params
    })
}

// 删除数据
export function deleteData(ids = []) {
    return http({
        url: http.adornUrl("/hbi/hbireport/delete"),
        method: "post",
        data: http.adornData(ids, false)
    })
}

// 根据ID 获取
export function getById(id) {
    return http({
        url: http.adornUrl(`/hbi/hbireport/info/${id}`),
        method: "get"
    })
}

// 更新或新增
export function saveOrUpdate(data) {
    return http({
        url: http.adornUrl(
            `/hbi/hbireport/${!data.id ? "save" : "update"}`
        ),
        method: "post",
        data: http.adornData(data)
    })
}

// 根据ID 获取
export function getReportList(params) {
    return http({
        url: http.adornUrl(`/hbi/hbireport/reportList`),
        method: "get",
        params: params
    })
}


// 发布
export function publishInfo(data) {
    return http({
        url: http.adornUrl(
            `/hbi/hbireport/publishInfo`
        ),
        method: "post",
        data: http.adornData(data)
    })
}

// 重新发布
export function againPublishInfo(data) {
    return http({
        url: http.adornUrl(
            `/hbi/hbireport/againPublishInfo`
        ),
        method: "post",
        data: http.adornData(data)
    })
}


// 根据ID撤回
export function revokeInfo(id) {
    return http({
        url: http.adornUrl(`/hbi/hbireport/revokeInfo/${id}`),
        method: "get"
    })
}


// 根据ID 获取
export function getInfoById(id) {
    return http({
        url: http.adornUrl(`/hbi/hbireportrelease/info/${id}`),
        method: "get"
    })
}


// 模板编辑更新
export function updateInfo(data) {
    return http({
        url: http.adornUrl(
            `/hbi/hbireport/updateInfo`
        ),
        method: "post",
        data: http.adornData(data)
    })
}

// 报表访问
export function saveReport(reportId) {
    return http({
        url: http.adornUrl(`/hbi/visitnumber/savereport/${reportId}`),
        method: "post"
    })
}

// 大屏访问
export function saveBscreen(reportId) {
    return http({
        url: http.adornUrl(`/hbi/visitnumber/savebscreen/${reportId}`),
        method: "post"
    })
}

// 项目访问
export function saveProject(projectId) {
    return http({
        url: http.adornUrl(`/hbi/visitnumber/saveproject/${projectId}`),
        method: "post"
    })
}


// 更新或新增
export function copy(data) {
    return http({
        url: http.adornUrl(
            `/hbi/hbireport/copy`
        ),
        method: "post",
        data: http.adornData(data)
    })
}

// 根据ID 获取用户默认首页
export function getListByUser(params) {
    return http({
        url: http.adornUrl(`/hbi/hbireport/getListByUser`),
        method: "get",
        params: params
    })
}

// 根据ID 获取用户默认首页
export function getAlllist(params) {
    return http({
        url: http.adornUrl(`/hbi/hbireport/getAlllist`),
        method: "get",
        params: params
    })
}