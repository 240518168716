<template>
    <el-dialog
            :title="!dataForm.id ? '新增' : '修改'"
            :close-on-click-modal="false"
            :visible.sync="visible">
        <el-form v-loading="formLoading" :model="dataForm" :rules="dataRule" ref="dataForm" label-width="110px">
            <el-form-item label="组织机构名称" prop="categoryName" >
                <el-input v-model="dataForm.categoryName" placeholder="分组名称" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="配置名称" prop="name" >
                <el-input v-model="dataForm.name" placeholder="配置名称" ></el-input>
            </el-form-item>
          <el-form-item label="要复制的报表" prop="reportId">
            <el-select v-model="dataForm.reportId" placeholder="请选择"   clearable filterable >
              <el-option v-for="item in hbiReports" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="dataForm.remark"
                          placeholder="备注" maxlength="1000" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" :loading="isOnSubmit">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :loading="formLoading || isOnSubmit">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import { getById , saveOrUpdate,getAlllist } from '@/api/hbi/report.js'
    import {copy} from "../../../../api/hbi/report";
    export default {
        data () {
            return {
                visible: false,
                isOnSubmit: false,
                formLoading: false,
                categoryOptions: [],
                dataForm: {
                    id: null,
                    categoryId: '',
                    remark: '',
                    categoryName: '',
                    name: '',
                    reportType: '',
                    reportId:''
                },
                categoryIdList : [],
                dataRule: {
                    name: [{ required: true, message: '配置名称不能为空', trigger: 'blur' }],
                    reportType: [{ required: true, message: '配置类型不能为空', trigger: 'blur' }],
                  reportId: [{ required: true, message: '要复制的报表不能为空', trigger: 'blur' }]

                },
              hbiReports:[]
            }
        },
        created() {
        },
        methods: {
            init (id,categoryId,categoryName) {
                this.visible = true
                this.isOnSubmit = false
                this.dataForm.id = id
                this.$nextTick(() => {
                    this.$refs['dataForm'].resetFields()
                    if (this.dataForm.id) {
                        this.formLoading = true
                        getById(id).then(({data}) => {
                            if (data && data.code === 0) {
                                let res = data.data
                                for(let item in this.dataForm){
                                    if(res[item] || res[item] === 0){
                                        this.dataForm[item] = res[item] + ''
                                    }else{
                                        this.dataForm[item] = ''
                                    }
                                }
                                console.log(res)
                            }
                        }).catch((err) => {
                            this.$message.error(err)
                        }).finally(() => {
                            this.formLoading = false
                        })
                    }else {
                        this.dataForm.categoryId = categoryId
                        this.dataForm.categoryName = categoryName
                    }
                    this.initReports()
                })
            },
            initReports() {
              getAlllist().then(({data})=> {
                this.hbiReports = data.data
              })
            },
            // 表单提交
            dataFormSubmit () {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.isOnSubmit = true
                        const data_ = {
                            'id': this.dataForm.id || undefined,
                            'categoryId': this.dataForm.categoryId,
                            'name': this.dataForm.name,
                            'remark': this.dataForm.remark,
                            'reportType': this.dataForm.reportType,
                            'reportId':this.dataForm.reportId
                        }

                      copy(data_).then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 500
                                })
                                this.visible = false
                                this.$emit('refreshDataList',this.dataForm.categoryId)
                            } else {
                                this.$message.error(data.msg)
                            }
                        }).catch((err) => {
                            this.$message.error(err)
                        }).finally(()=>{
                            this.isOnSubmit = false
                        })
                    }
                })
            }
        }
    }
</script>
<style scoped>
</style>